import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../layout/seo";
import RecipesList from "../components/recipesList";

const CategorieTemplate = ({ data, pageContext: { categorie: title } }) => {
  return (
    <Layout>
      <Seo />
      <div className="section">
        <div className="column is-10 is-offset-1 ">
          <h2 className="title is-1">{title}</h2>
        </div>
        <div className="columns is-centered">
          <div className="column is-10 ">
            <RecipesList recettes={data.allNotion.nodes} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($categorie: String!) {
    allNotion(
      filter: {
        childMarkdownRemark: {
          frontmatter: {
            Cat_gories: { elemMatch: { name: { eq: $categorie } } }
          }
        }
      }
    ) {
      nodes {
        id
        childMarkdownRemark {
          frontmatter {
            ajouteLe: Ajout_e_le(fromNow: true, locale: "fr")
            tags: Cat_gories {
              id
              name
            }
            title
          }
        }
        raw {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                layout: CONSTRAINED
                width: 1024
              )
            }
          }
        }
      }
    }
  }
`;

export default CategorieTemplate;
